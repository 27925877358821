<template>
  <div tabindex="0">
    <template v-if="!vueMaterialStyle">
      <ValidationProvider :rules="isRequired  ? 'required' : ''" name="OmniMajorCategory" v-slot="{ errors, classes }" slim>
        <div>
          <select v-model="selectedValue">
            <option v-bind:value="null" selected disabled>{{ $t('app.choose') }}</option>
            <option v-for="(omniMajorCategory, index) in omniMajorCategories" :key="index" :value="omniMajorCategory.OmniMajorCategoryId">
              {{ omniMajorCategory.Code }}
            </option>
          </select>
          <span class="msgError">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
    </template>
    <template v-else>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <ValidationProvider :rules="isRequired  ? 'required' : ''" name="OmniMajorCategory" v-slot="{ errors, classes }" slim>
            <md-field>
              <label for="omniMajorCategory">{{$t('tag.category')}}</label>
              <md-select v-model="selectedValue">
                <md-option :value="null" selected>{{ $t('app.none') }}</md-option>
                <md-option v-for="(omniMajorCategory, index) in omniMajorCategories" :key="index" :value="omniMajorCategory.OmniMajorCategoryId">
                  {{ omniMajorCategory.Code }}
                </md-option>
              </md-select>
              <span class="msgError">{{ errors[0] }}</span>
            </md-field>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    components: {
      ValidationProvider
    },
    data() {
      return {
        selectedValue: ''
      };
    },
    props: {
      value: {
        required: true
      },
      vueMaterialStyle: {
        type: Boolean,
        default: false
      },
      isRequired: {
        type: [Boolean]
      }
    },
    mounted: function () {
      this.$store.dispatch('category/LoadOmniMajorCategories');
    },
    computed: {
      omniMajorCategories() {
        return this.$store.state.category.omniMajorCategories;
      }
    },
    methods: {
      setSelectedOption(option) {
        this.selectedValue = option;
      }
    },
    watch: {
      exceptedValues() {
        this.setOptions();
      },
      value: {
        handler(newValue) {
          this.selectedValue = newValue;
        },
        immediate: true
      },
      selectedValue(newValue) {
        this.$emit('input', newValue);
      }
    }
  };
</script>
