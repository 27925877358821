class ProductCategoryStatusCodeEnumWeb {
  constructor() {
    this.None = 0;
    this.Unpublished = 1;
    this.Missing = 2;
    this.Published = 3; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let productCategoryStatusCodeEnumWeb = new ProductCategoryStatusCodeEnumWeb();