class PublishStatusCodeEnumWeb {
  constructor() {
    this.None = 0;
    this.NotPublished = 1;
    this.Omni = 2;
    this.Web = 3;
    this.OmniAndWeb = 4; 
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let publishStatusCodeEnumWeb = new PublishStatusCodeEnumWeb();