<template>
  <div class="md-layout-item productsListing">
    <md-table class="productsListingContent" v-model="productCategories" md-sort="code" md-sort-order="asc" md-card>

      <md-table-toolbar class="sticky">
        <div class="sectionTitle">
          <h1 class="md-title">{{$t('productCategory.productCategoriesSummary')}}</h1>
          <md-button class="md-primary md-raised export-button" @click="downloadCategoriesSummary" :disabled="exporting">
            {{ $t('product.export') }}
          </md-button>
        </div>
        <div class="sectionTitle">
          <h4>{{ $t('productCategory.umbracoIdHint') }}</h4>
        </div>
      </md-table-toolbar>

      <md-table-empty-state :md-label="error ? $t('app.error') : $t('productCategory.noProductCategoryFound')" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell :md-label="$t('productCategory.umbracoId')" >{{ item.UmbracoId }}</md-table-cell>
        <md-table-cell :md-label="$t('productCategory.title')" :md-sort-by="$t('productCategory.code')">{{ item.Title }}</md-table-cell>
        <md-table-cell :md-label="$t('productCategory.categoryType')" >{{ getCategoryType(item) }}</md-table-cell>
        <md-table-cell :md-label="$t('productCategory.status')" >{{ $t(`productCategoryStatusCode.${ProductCategoryStatusCodeEnumWeb.GetMatchingKey(item.IsPublished ? ProductCategoryStatusCodeEnumWeb.Published : ProductCategoryStatusCodeEnumWeb.Unpublished)}`) }}</md-table-cell>
        <md-table-cell :md-label="$t('productCategory.missingOmniCategoryCode')" >{{ item.HasMissingOmniCategoryCode ? $t('app.yes') : $t('app.no') }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
  import { productCategoryStatusCodeEnumWeb } from '../models/GeneratedModels/ProductCategoryStatusCodeEnumWeb.js';
  import authenticatedAxios from '@/authenticatedAxios';

  export default {
    name: 'ProductCategoriesSummary',
    data: () => ({
      error: false,
      exporting: false,
      loading: false,
      ProductCategoryStatusCodeEnumWeb: productCategoryStatusCodeEnumWeb,
      productCategories: []
    }),
    computed: {
      hasManagerPermissions() {
        return this.$store.state.app.userInfo.MemberType === this.MemberTypeEnum.Manager;
      }
    },
    created() {
      this.getProductCategoriesSummary();
    },
    methods: {
      async downloadCategoriesSummary() {
        if (this.exporting) {
          return;
        }

        this.exporting = true;
        let response = await authenticatedAxios.get('/api/product/GetCategoriesSummaryFile');
        var blob = new Blob([response.data], { type: 'text/plain' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.download = 'categories_sommaire.csv';
        document.body.appendChild(a);
        a.href = url;
        a.click();
        document.body.removeChild(a);
        this.exporting = false;
      },
      getCategoryType(productCategory) {
        if (productCategory.IsMajorCategory) {
          return this.$i18n.t('productCategory.majorCategory');
        } else if (productCategory.IsIntermediateCategory) {
          return this.$i18n.t('productCategory.intermediateCategory');
        } else if (productCategory.IsMinorCategory) {
          return this.$i18n.t('productCategory.minorCategory');
        }

        return 'N/A';
      },
      async getProductCategoriesSummary() {
        this.loading = true;
        try {
          let response = await authenticatedAxios.get('/api/product/GetProductCategoriesSummary');
          this.productCategories = response.data.ProductCategories;
        } catch {
          this.loading = false;
          this.error = true;
          this.productCategories = [];
        }
      }
    }
  };
</script>

<style lang="scss">
  .productsListing{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }
  .productsListing .md-toolbar{
    padding-top: 1.00rem;
    border-bottom: 1px solid #dadada;
    padding: 1.50rem 3.00rem;
  }
  .productsListing .sectionTitle{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width:100%;
    padding-bottom:1.063rem;
  }
  .productsListing .optionsFilter{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width:100%;
  }
  .productsListing .md-title{
    font-size: 1.75rem;
    color: #2a2a2a;
    font-weight: 700;
    margin:0;
  }
  .productsListing .md-field.md-theme-default.md-focused label{
    color:#2a2a2a;
  }
  .productsListing .md-field.md-theme-default.md-focused .md-input{
    color:#2a2a2a;
  }
  .productsListing .md-field.md-theme-default:before{
    background-color: #2a2a2a;
  }
  .productsListing .md-checkbox.md-theme-default.md-checked .md-checkbox-container{
    background-color:#e20613;
    border-color:#e20613;
  }
  .productsListing .md-table.md-theme-default .md-table-alternate-header .md-table-toolbar{
    background-color:rgba(255, 95, 95, 0.2);
    padding: 0.750rem 3.00rem;
  }
  .md-list.md-theme-default .md-selected .md-list-item-content,
  .md-list.md-theme-default .router-link-active .md-list-item-content{
    color:#2a2a2a;
    font-weight:700;
  }

  .productsListing .productsListingContent{
    width:100%;
    height:100%;
    box-shadow:none;
    overflow: inherit;
  }
  .productsListing .sticky{
    background-color:#fff!important;
    position:sticky;
    top:0;
    z-index: 10;
    display:block;
  }
  .productsListing .md-table-alternate-header{
    z-index:20;
  }
  .productsListing .md-table-row{
    cursor:pointer;
  }
  .filterContent{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }
  .filterContent > div{
    margin-right:1.00rem;
  }

  .file-select .md-button-content > input[type="file"] {
  display: none;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  padding: 20px;
  border-radius: 4px;
  width:100%;


  > li {
    display: inline; // Remove list-style and block-level defaults
      outline:none;
    > a,
    > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height:1.42857143;
      text-decoration: none;
      color:#fff;
      background-color:#fff;
      border: 1px solid #ddd;
      margin-left: -1px;
      outline:none;
    }
    &:first-child {
      > a,
      > span {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      }
    }
    &:last-child {
      > a,
      > span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
    z-index: 3;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
    text-decoration:none;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #e20613;
    border-color: #e20613;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
    }
  }
}

.header-tools {
  display: flex;

  .filter {
    margin-right: 10px;
  }
}
  .header-tools .filter .md-layout.md-gutter{
    margin:0;
  }
  .header-tools .filter .md-layout.md-gutter .md-layout-item{
    padding:0;
  }

  .md-theme-default .pagination  a:not(.md-button){
    color:#2a2a2a;
  }
  .md-theme-default .pagination .active  a:not(.md-button){
    color:#ffffff;
  }
</style>
