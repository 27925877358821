<template>
  <div>
    <md-tabs>
      <md-tab id="tab-products" md-label="Produits">
        <incomplete-products />
      </md-tab>
      <md-tab id="tab-product-categories" md-label="Catégories">
        <product-categories-summary />
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
  import IncompleteProducts from '@/components/IncompleteProducts.vue';
  import ProductCategoriesSummary from '@/components/ProductCategoriesSummary.vue';

  export default {
    name: 'Summary',
    components: {
      IncompleteProducts,
      ProductCategoriesSummary
    }
  };
</script>
